// import React from 'react';

// const Process = () => {
//   return (
//     <section className="bg-black pb-10">
//       <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-5 '>Our Process</h2>
//       <div className='min-w-[80%] max-w-[1000px] ml-auto mr-auto md:block hidden'>
//         <img src={process.env.PUBLIC_URL + '/process2.png'} alt="Discovery and Consultation" />
//       </div>
//       <div className='w-[90%] ml-auto mr-auto md:hidden block'>
//         <div className=' w-[100%] h-[250px] bg-[#ffcd4c] border-dashed border-[4px] border-white  mt-4 rounded-[24px] flex items-center justify-center flex-col'>
//           <h2 className='text-black font-semibold text-xl w-fit'>Step 1</h2>
//           <p className='text-black font-montserrat'>Discovery and Consultation.</p>
//         </div>
//         <div className='bg-[#003eff]  text-white w-[100%] h-[250px] border-dashed border-[4px] border-white bg-contain mt-4 rounded-[24px] flex items-center justify-center flex-col'>
//         <h2 className='text-black font-semibold text-xl'>Step 2</h2>
//           <p className='text-black font-montserrat' >Planning and Strategy.</p>
//         </div>
//         <div className=' w-[100%] h-[250px] bg-contain border-dashed border-[4px] border-white  bg-[#ffcd4c] mt-4 rounded-[24px] flex items-center justify-center flex-col'>
//         <h2 className='text-black font-semibold text-xl'>Step 3</h2>
//           <p className='text-black font-montserrat'>Design and Development.</p>
//         </div>
//         <div className='bg-[#003eff] text-white border-dashed border-[4px] border-white w-[100%] h-[250px] bg-contain mt-4 rounded-[24px] flex items-center justify-center flex-col'>
//         <h2 className='text-black font-semibold text-xl'>Step 4</h2>
//           <p className='text-black font-montserrat'>Testing and Quality Assurance.</p>
//         </div>
//         <div className=' w-[100%] h-[250px] bg-contain border-dashed border-[4px] border-white  bg-[#ffcd4c] mt-4 rounded-[24px] flex items-center justify-center flex-col'>
//         <h2 className='text-black font-semibold text-xl'>Step 5</h2>
//           <p className='text-black font-montserrat'>Deployment and Support</p>
//         </div>
//       </div>
//       {/* <ol>
//         <li>Discovery and Consultation</li>
//         <li>Planning and Strategy</li>
//         <li>Design and Development</li>
//         <li>Testing and Quality Assurance</li>
//         <li>Deployment and Support</li>
//       </ol> */}
//     </section>
//   );
// };

// export default Process;



import React from "react";
import { motion } from "framer-motion";
import { CheckCircle, Rocket, Code, TrendingUp } from "lucide-react";

const steps = [
  {
    title: "Consultation",
    description: "We discuss your needs and craft a strategy tailored to your business.",
    icon: <CheckCircle className="w-10 h-10 text-blue-500" />,
  },
  {
    title: "Planning & Strategy",
    description: "We outline a detailed roadmap for design, development, and execution.",
    icon: <Rocket className="w-10 h-10 text-green-500" />,
  },
  {
    title: "Development & Implementation",
    description: "We bring the strategy to life with cutting-edge technology.",
    icon: <Code className="w-10 h-10 text-purple-500" />,
  },
  {
    title: "Launch & Optimization",
    description: "We deploy, test, and optimize for the best performance.",
    icon: <TrendingUp className="w-10 h-10 text-yellow-500" />,
  },
];

const Process = () => {
  return (
    <section id="Process" className="bg-black py-16">
      <div className="text-center mb-12">
        <h2 className="text-white text-3xl md:text-5xl font-bold font-poppins">Our Process</h2>
        <p className="text-gray-400 text-lg mt-4 max-w-3xl mx-auto">
          We follow a structured and strategic approach to ensure your business achieves its goals.
        </p>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center gap-8">
        {steps.map((step, idx) => (
          <motion.div
            key={idx}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: idx * 0.2 }}
            className="bg-gray-900 p-8 rounded-2xl shadow-lg text-center max-w-xs"
          >
            <div className="mb-4 flex justify-center">{step.icon}</div>
            <h3 className="text-white text-xl font-semibold">{step.title}</h3>
            <p className="text-gray-300 mt-2">{step.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Process;
