// import React from 'react';
// import SvgIcons from './svg';

// const FAQs = () => {
//   const [faqs, setFaqs] = React.useState([
//     {
//       title: 'What services does Unitech Agency provide?',
//       answer:
//         'We offer a range of services including custom software development, web development, mobile app development, IT consulting, and cloud solutions. Our goal is to provide tailored technology solutions to meet your business needs.',
//       show: false,
//     },
//     {
//       title: 'How can I get a quote for my project?',
//       answer:
//         'You can get a quote by contacting us through our Contact Form or by emailing us at contact@unitechagency.com. Provide details about your project and our team will get back to you with a tailored proposal.',
//       show: false,
//     },
//     {
//       title: 'What industries do you specialize in?',
//       answer:
//         'We have experience working with a variety of industries including finance, healthcare, e-commerce, education, and more. Our versatile team is equipped to handle projects across different sectors.',
//       show: false,
//     },
//     {
//       title: 'Do you offer support and maintenance services?',
//       answer:
//         'Yes, we offer comprehensive support and maintenance services to ensure your technology solutions remain up-to-date and perform optimally. This includes regular updates, troubleshooting, and enhancements.',
//       show: false,
//     },
//     {
//       title: 'How long does it take to complete a project?',
//       answer:
//         'The timeline for project completion varies depending on the scope and complexity of the project. Once we understand your requirements, we can provide a detailed timeline and keep you updated throughout the development process.',
//       show: false,
//     },
//   ]);
//   const hanldeHide = (idx) => {
//     let temp = [...faqs];
//     temp[idx].show = !temp[idx].show;
//     setFaqs(temp);
//   };
//   return (
 
//     <section id='FAQs' class='bg-black pb-[200px]'>
//       <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-10 '>
//         Frequently Asked Questions
//       </h2>
//       <div className='w-full flex flex-col items-center mt-10 gap-y-5'>
//         {faqs?.map((ele, idx) => {
//           return (
//             <div
//               className='border border-[#ffffff] md:w-[700px] w-[90%] pt-5 pb-5 rounded-lg cp'
//               onClick={() => {
//                 hanldeHide(idx);
//               }}
//             >
//               <div className='flex justify-between pl-5 pr-5 gap-10 '>
//                 <h2 className={`text-left font-opensans text-lg ${ ele.show && 'text-[#4594ff] transition duration-200 ease-in-out'}` }>{ele.title}</h2>
//                 <div>
//                   {ele.show ? <SvgIcons.ArrowUp /> : <SvgIcons.ArrowDown />}
//                 </div>
//               </div>
//               {ele.show && <p className='text-left font-montserrat pl-5 pr-5 pt-9 '>{ele.answer}</p>}
//             </div>
//           );
//         })}
//       </div>
     
//     </section>
//   );
// };

// export default FAQs;



import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronUp } from "lucide-react";

const faqsData = [
  {
    title: "What services does Unitech Agency provide?",
    answer:
      "We offer custom software development, web development, mobile app development, IT consulting, and cloud solutions tailored to your business needs.",
  },
  {
    title: "How can I get a quote for my project?",
    answer:
      "You can get a quote by contacting us via our Contact Form or emailing contact@unitechagency.com with your project details.",
  },
  {
    title: "What industries do you specialize in?",
    answer:
      "We work with finance, healthcare, e-commerce, education, and more. Our versatile team can handle projects across different industries.",
  },
  {
    title: "Do you offer support and maintenance services?",
    answer:
      "Yes, we provide ongoing support and maintenance, including updates, troubleshooting, and enhancements to keep your tech solutions optimized.",
  },
  {
    title: "How long does it take to complete a project?",
    answer:
      "Timelines vary based on project scope and complexity. We provide detailed timelines and keep you updated throughout the process.",
  },
];

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="FAQs" className="bg-black py-16">
      <div className="w-[90%] md:w-[70%] mx-auto text-white">
        <h2 className="text-center font-poppins text-3xl md:text-4xl font-bold mb-10">
          Frequently Asked <span className="text-blue-400">Questions</span>
        </h2>

        <div className="flex text-start flex-col gap-y-5">
          {faqsData.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }} // Faster appearance
              className="bg-[#192346] rounded-xl shadow-md p-5 cursor-pointer hover:shadow-xl transition-shadow duration-200"
              onClick={() => toggleFAQ(index)}
            >
              <div className="flex justify-between items-center">
                <h3
                  className={`font-semibold text-lg transition-colors duration-200 ${
                    openIndex === index ? "text-blue-400" : "text-white"
                  }`}
                >
                  {faq.title}
                </h3>
                <motion.div
                  animate={{ rotate: openIndex === index ? 180 : 0 }}
                  transition={{ type: "spring", stiffness: 500, damping: 25 }} // Snappier rotation
                >
                  {openIndex === index ? (
                    <ChevronUp className="text-blue-400" />
                  ) : (
                    <ChevronDown className="text-gray-400" />
                  )}
                </motion.div>
              </div>

              <AnimatePresence>
                {openIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{
                      duration: 0.3, // Faster expansion
                      ease: "easeInOut",
                    }}
                    className="overflow-hidden text-gray-300 text-base pt-3"
                  >
                    {faq.answer}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQs;
