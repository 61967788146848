import React from 'react';
import Header from './Header';
import CalendlyWidget from './CalendlyWidget';

const HeroSection = () => {
  const handleCalendlyOpen = () => {
    window.open(
      'https://calendly.com/business-unitechagency/30_mins?background_color=1a1a1a&text_color=ffffff&primary_color=5d9cf5',
      '_blank'
    );
  };
  return (
    <section id='Home' className='bg-Video h-screen  p-0 bg-black'>
      <Header />
      <section className='flex flex-col justify-center items-center mt-[200px] z-10'>
        <h1 className='font-manrope hidden md:block md:text-6xl md:font-semibold font-medium text-4xl text-white leading-8'>
          The world is moving fast.
          <br /> We help you move faster.
        </h1>
        <h1 className='font-manrope block mt-5 md:hidden md:text-6xl md:font-semibold font-medium text-4xl text-white '>
          The world is moving fast.We help you move faster.
        </h1>
        <h2 className='font-manrope mt-6 text-white md:text-lg text-base'>
          Your Partner in Cutting-Edge Technology Solutions
        </h2>
        <button
          className='font-opensans hover:text-[#4594ff] hover:bg-[#ffffff] transition duration-300 ease-in-out bg-[#4594ff] text-[#ffffff] font-semibold py-4 px-6 mt-6 rounded-3xl'
          onClick={handleCalendlyOpen}
        >
          Speak To Uni Team
        </button>
      </section>
    </section>
  );
};

export default HeroSection;
