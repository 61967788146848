// import React from 'react';
// import SvgIcons from './svg';
// const Services = () => {
//   const [services, setServices] = React.useState([
//     {
//       heading: 'Custom Software Development',
//       description: 'Tailored software solutions to meet your business needs.',
//       icon: <SvgIcons.Software />,
//     },
//     {
//       heading: 'Web Development',
//       description: 'Building responsive and high-performing websites.',
//       icon: <SvgIcons.WebIcon />,
//     },
//     {
//       heading: 'Mobile App Development',
//       description:
//         'Creating user-friendly mobile applications for iOS and Android.',
//       icon: <SvgIcons.Mobile />,
//     },
//     {
//       heading: 'IT Consulting',
//       description:
//         'Professional advice to enhance your IT infrastructure and strategy.',
//       icon: <SvgIcons.Consulting />,
//     },
//     {
//       heading: 'Cloud Solutions',
//       description:
//         'Scalable and secure cloud services to support your business operations.',
//       icon: <SvgIcons.Cloud />,
//     },
//   ]);
//   return (
//     <div id='Services' className={`bg-Image pt-10 pb-10 bg-cover`}>
//        
//       <h2 className='font-opensans text-2xl md:text-4xl font-bold pt-9  pb-10 '>
//         Our Services
//       </h2>
//       <div className='w-full flex justify-center flex-wrap gap-5'>
//         {services.map((ele, idx) => {
//           return (
//             <div className='rounded-[20px] border-[#ffffff] md:w-[40%] w-[90%] bg-[#000000]  border h-[150px]  flex items-center p-4 gap-4'>
//               <div className='p-2 rounded-[16px]'>
//                 {ele.icon}
//               </div>
//               <div>
//                 <h3 className=' font-semibold text-white font-opensans text-xl text-left '>
//                   {ele.heading}
//                 </h3>
//                 <p className='text-left font-montserrat font-base text-white'>
//                   {' '}
//                   {ele.description}
//                 </p>
//               </div>
//             </div>
//           );
//         })}
//       </div>

//     </div>
//   );
// };

// export default Services;


import React from "react";
import { Code, Globe, Smartphone, Lightbulb, Cloud } from "lucide-react";

const services = [
  {
    heading: "Custom Software Development",
    description: "Tailored software solutions to meet your business needs.",
    icon: <Code size={40} className="text-blue-400" />,
  },
  {
    heading: "Web Development",
    description: "Building responsive and high-performing websites.",
    icon: <Globe size={40} className="text-green-400" />,
  },
  {
    heading: "Mobile App Development",
    description: "Creating user-friendly mobile applications for iOS and Android.",
    icon: <Smartphone size={40} className="text-purple-400" />,
  },
  {
    heading: "IT Consulting",
    description: "Professional advice to enhance your IT infrastructure and strategy.",
    icon: <Lightbulb size={40} className="text-yellow-400" />,
  },
  {
    heading: "Cloud Solutions",
    description: "Scalable and secure cloud services to support your business operations.",
    icon: <Cloud size={40} className="text-cyan-400" />,
  },
];

export default function Services() {
  return (
    <div id="Services" className="bg-black py-20 px-6">
      <div className="max-w-6xl mx-auto text-center">
        
        {/* Section Title */}
        <h2 className="text-white text-4xl md:text-5xl font-extrabold mb-6">
          Our <span className="text-blue-400">Services</span>
        </h2>

        <p className="text-gray-400 text-lg md:text-xl max-w-2xl mx-auto">
          We provide cutting-edge technology solutions tailored to your business needs.
        </p>

        {/* Services Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          {services.map((service, idx) => (
            <div
              key={idx}
              className="bg-white/10 backdrop-blur-md p-6 rounded-2xl flex flex-col items-center text-center shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-blue-500/50"
            >
              {/* Service Icon */}
              <div className="bg-white/20 p-4 rounded-full">
                {service.icon}
              </div>

              {/* Service Heading */}
              <h3 className="text-white text-2xl font-semibold mt-4">
                {service.heading}
              </h3>

              {/* Service Description */}
              <p className="text-gray-300 text-sm mt-2">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
