// const WhyChoose = () => {
//   return (
//     <section className="bg-black">
//       <div className="md:w-[70%] w-[90%] bg-[#192346] p-5 ml-auto mr-auto rounded-[19px] flex md:flex-row flex-col">
//         <div className="md:w-[50%] flex items-center">
//             <img src={process.env.PUBLIC_URL + '/whyChoose.png'} />
//         </div>
//         <div className="md:w-[50%] flex flex-col justify-center mt-10 md:mt-0">
//             <h2 className="font-bold md:text-3xl text-2xl font-opensans pb-5">Why Choose UNI Team</h2>
//             <p className="font-montserrat md:text-xl">We are not just any agency; we aim to be an extension of your team. With open communication guaranteed 40 hours a week, we ensure that we're always there for you, ready to guide, support, and grow your business.</p>
//         </div>
//       </div>
//     </section>
//   );
// };
// export default WhyChoose;


import React from "react";
import { motion } from "framer-motion";

const WhyChoose = () => {
  return (
    <section className="bg-black py-16">
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="md:w-[70%] w-[90%] bg-gradient-to-r from-[#192346] to-[#25335F] p-8 mx-auto rounded-2xl shadow-2xl flex md:flex-row flex-col items-center"
      >
        {/* Image Section */}
        <motion.div
          initial={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="md:w-[50%] flex items-center justify-center"
        >
          <img
            src={process.env.PUBLIC_URL + "/whyChoose.png"}
            alt="Why Choose Us"
            className="rounded-lg shadow-lg"
          />
        </motion.div>

        {/* Text Content */}
        <motion.div
          initial={{ opacity: 0, x: 30 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="md:w-[50%] flex flex-col justify-center text-center md:text-left mt-10 md:mt-0 px-5"
        >
          <h2 className="font-bold md:text-4xl text-3xl text-white font-poppins pb-4 leading-tight">
            Why Choose <span className="text-blue-400">UNI Team?</span>
          </h2>
          <p className="font-montserrat text-gray-300 md:text-lg text-base leading-relaxed">
            We are more than just an agency – we’re an <b> extension of your team</b>.
            With <b> open communication</b> guaranteed <b>40+ hours a week</b>, we’re always 
            there to <b> guide, support, and grow your business</b>. Your success is our mission.
          </p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default WhyChoose;
