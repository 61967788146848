import React from 'react';
import SvgIcons from './svg';

const Footer = () => {
  return (

    // <footer className=' dark:bg-black border-t-2 border-[#4594ff] '>
    //   <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
    //     <div className='sm:flex sm:items-center sm:justify-between'>
    //     <ul className='flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white'>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             Home
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             About
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             Services
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline'>
    //            Portfolio
    //           </a>
    //         </li>
    //       </ul>
    //       <ul className='flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white'>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             Facebook
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             Twitter
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline me-4 md:me-6'>
    //             LinkedIn
    //           </a>
    //         </li>
    //         <li>
    //           <a href='#' className='hover:underline'>
    //            Instagram
    //           </a>
    //         </li>
    //       </ul>
    //     </div>
    //     <hr className='my-6 border-[#4594ff] sm:mx-auto dark:border-[#4594ff] lg:my-8' />
    //     <span className='block text-sm text-white sm:text-center dark:text-white'>
    //       © 2024{' '}
    //       <a  className='hover:underline'>
    //         Unitech
    //       </a>
    //       . All Rights Reserved.
    //     </span>
    //   </div>
    // </footer>
    <footer className="relative bg-black pt-8 pb-6">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap text-left lg:text-left justify-center">
      <div className="w-full lg:w-4/12 px-4">
        <h4 className="text-3xl font-semibold text-white mb-4"> <img
                    className="w-[150px]"
                    src={process.env.PUBLIC_URL + '/Unitech-logo-White.png'}
                    alt="Your Company"
                  /> </h4>
        <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
        Your Partner in Cutting-Edge Technology Solutions
        </h5>
        <div className='flex gap-2 mb-4'>
          <div className='w-[30px] h-[30px]'>
            <img src={process.env.PUBLIC_URL + '/LinkedinLogo.png'} alt='linkedinicon' />
          </div>
          <div className='w-[30px] h-[30px]'>
            <img src={process.env.PUBLIC_URL + '/TwitterLogo.png'} alt='twittericon' />
          </div>
          <div className='w-[30px] h-[30px]'>
            <img src={process.env.PUBLIC_URL + '/InstagramLogo.png'} alt='instaicon' />
          </div>
        </div>
        {/* <div className="mt-6 lg:mb-0 mb-6">
          <button className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i className="fab fa-twitter"></i></button><button className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i className="fab fa-facebook-square"></i></button><button className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i className="fab fa-dribbble"></i></button><button className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
            <i className="fab fa-github"></i>
          </button>
        </div> */}
      </div>
      <div className="w-full lg:w-6/12 px-4">
        <div className="flex flex-wrap items-top mb-6">
          <div className="w-full lg:w-6/12 px-4 ">
            <span className="block uppercase text-blueGray-500 text-xl font-bold mb-2 border-b-2 border-[#4594ff] ">Useful Links</span>
            <ul className="list-unstyled w-full">
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#Home' >Home</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#About'>About Us</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#Services'>Services</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#Reviews'>Reviews</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#Team'>Team</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href='#FAQs'>FAQ's</a>
              </li>
              <li>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm hover:underline" href="#Contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <span className="block uppercase text-blueGray-500 text-xl font-bold mb-2 border-b-2 border-[#4594ff]">Contact Info</span>
            <ul className="list-unstyled">
              {/* <li className='flex gap-2 items-center pb-2'>
                <div><SvgIcons.Location /></div>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold   text-sm flex gap-2" > 1930 S. Alma School A115,Mesa, AZ 85202</a>
              </li> */}
              <li className='flex gap-2 items-center pb-2'>
                <div><SvgIcons.Email/></div>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold flex gap-2  text-sm" > business@unitechagency.com</a>
              </li>
              <li className='flex gap-2 items-center pb-2'>
                <div><SvgIcons.Call/></div>
                <a className="text-blueGray-600 hover:text-blueGray-800 font-semibold flex gap-2  text-sm" > +1 (949) 407-8098</a>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr className="my-6 border-blueGray-300" />
    <div className="flex flex-wrap items-center md:justify-between justify-center">
      <div className="w-full md:w-4/12 px-4 mx-auto text-center">
        <div className="text-sm text-blueGray-500 font-semibold py-1">
        Copyright ©️ 2024 UNI Tech Agency
        </div>
      </div>
    </div>
  </div>
</footer>
  );
};

export default Footer;
