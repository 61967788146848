function WebIcon() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='80px'
        viewBox='0 -960 960 960'
        width='80px'
        fill='#4594ff'
      >
        <path d='M200-120q-33 0-56.5-23.5T120-200v-500q0-14 4.5-26.5T138-750l56-68q9-11 20.5-16.5T240-840h480q14 0 25.5 5.5T766-818l56 68q9 11 13.5 23.5T840-700v500q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm-16 520h560v-440H200v440Zm382-78 142-142-142-142-58 58 84 84-84 84 58 58Zm-202 0 58-58-84-84 84-84-58-58-142 142 142 142Zm-180 78v-440 440Z' />
      </svg>
    </>
  );
}
function Software() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='80px'
        viewBox='0 -960 960 960'
        width='80px'
        fill='#4594ff'
      >
        <path d='M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H160v400Zm140-40-56-56 103-104-104-104 57-56 160 160-160 160Zm180 0v-80h240v80H480Z' />
      </svg>{' '}
    </>
  );
}
function Mobile() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='80px'
        viewBox='0 -960 960 960'
        width='80px'
        fill='#4594ff'
      >
        <path d='M344-296 160-480l184-184 42 44-140 140 140 140-42 44Zm-144 30h60v76h440v-76h60v166q0 24-18 42t-42 18H260q-24 0-42-18t-18-42v-166Zm60-440h-60v-154q0-24 18-42t42-18h440q24 0 42 18t18 42v154h-60v-64H260v64Zm0 576v30h440v-30H260Zm0-700h440v-30H260v30Zm356 534-42-44 140-140-140-140 42-44 184 184-184 184ZM260-830v-30 30Zm0 700v30-30Z' />
      </svg>
    </>
  );
}
function Consulting() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='80px'
        viewBox='0 -960 960 960'
        width='80px'
        fill='#4594ff'
      >
        <path d='M42-120v-92q0-34 16-56.5t45-36.5q54-26 115.5-43T358-365q78 0 139.5 17T613-305q29 14 45 36.5t16 56.5v92H42Zm60-60h512v-32q0-15-8.5-24.5T585-251q-43-19-96-36.5T358-305q-78 0-131 17.5T131-251q-12 5-20.5 14.5T102-212v32Zm256-245q-66 0-108-43t-42-107h-10q-8 0-14-6t-6-14q0-8 6-14t14-6h10q0-40 20-72t52-52v39q0 6 4.5 10.5T295-685q7 0 11-4.5t4-10.5v-52q8-2 22-3.5t27-1.5q13 0 27 1.5t22 3.5v52q0 6 4 10.5t11 4.5q6 0 10.5-4.5T438-700v-39q32 20 51 52t19 72h10q8 0 14 6t6 14q0 8-6 14t-14 6h-10q0 64-42 107t-108 43Zm0-60q42 0 66-25t24-65H268q0 40 24 65t66 25Zm302 124-2-29q-7-4-14.5-9T630-409l-26 14-22-32 26-19q-2-4-2-7.5v-15q0-3.5 2-7.5l-26-19 22-32 26 14 14-10q7-5 14-9l2-29h40l2 29q7 4 14 9l14 10 26-14 22 32-26 19q2 4 2 7.5v15q0 3.5-2 7.5l26 19-22 32-26-14q-6 5-13.5 10t-14.5 9l-2 29h-40Zm20-62q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm88-155-9-35q-10-4-20.5-11T721-639l-44 16-20-35 35-28q-2-5-3.5-11t-1.5-12q0-6 1.5-12t3.5-11l-35-28 20-35 44 16q7-8 17.5-15.5T759-805l9-35h38l9 35q10 3 20.5 10.5T853-779l44-16 20 35-35 28q2 5 3.5 11t1.5 12q0 6-1.5 12t-3.5 11l35 28-20 35-44-16q-7 8-17.5 15T815-613l-9 35h-38Zm19-73q25 0 41.5-16.5T845-709q0-25-16.5-41.5T787-767q-25 0-41.5 16.5T729-709q0 25 16.5 41.5T787-651ZM358-180Z' />
      </svg>
    </>
  );
}
function Cloud() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='80px'
        viewBox='0 -960 960 960'
        width='80px'
        fill='#4594ff'
      >
        <path d='M250-160q-86 0-148-62T40-370q0-78 49.5-137.5T217-579q20-97 94-158.5T482-799q113 0 189.5 81.5T748-522v24q72-2 122 46.5T920-329q0 69-50 119t-119 50H510q-24 0-42-18t-18-42v-258l-83 83-43-43 156-156 156 156-43 43-83-83v258h241q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-89-60.5-153T478-739q-89 0-150 64t-61 153h-19q-62 0-105 43.5T100-371q0 62 43.93 106.5T250-220h140v60H250Zm230-290Z' />
      </svg>{' '}
    </>
  );
}
function ArrowDown() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#ffffff'
      >
        <path d='M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z' />
      </svg>
    </>
  );
}
function ArrowUp() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#ffffff'
      >
        <path d='M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z' />
      </svg>
    </>
  );
}
function ArrowLeft() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='40px'
        viewBox='0 -960 960 960'
        width='40px'
        fill='#e8eaed'
      >
        <path d='M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z' />
      </svg>
    </>
  );
}
function ArrowRight() {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='40px'
        viewBox='0 -960 960 960'
        width='40px'
        fill='#e8eaed'
      >
        <path d='M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z' />
      </svg>
    </>
  );
}
function Location() {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4594ff"><path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z"/></svg>
    </>
  );
}
function Email() {
  return (
    <>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4594ff"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
    </>
  );
}
function Call() {
  return (
    <>
   <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4594ff"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/></svg>
    </>
  );
}

const SvgIcons = {
  Call,
  Email,
  Location,
  WebIcon,
  Consulting,
  Software,
  Mobile,
  Cloud,
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
};

export default SvgIcons;
